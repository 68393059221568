import {
	TABLET_M_BP,
	DESKTOP_L_BP,
	DESKTOP_M_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import styled from "styled-components";

export const TwoColorWrapper = styled.div`
	background: linear-gradient(
		var(--primary-neutral-white) 0%,
		var(--primary-neutral-white) 50%,
		var(--primary-forest-600) 50%,
		var(--primary-forest-600) 100%
	);
	width: 100%;
	padding: 40px 96px;
	padding-top: 80px;

	@media only screen and (max-width: ${DESKTOP_L_BP}px) {
		padding: 40px 48px;
	}

	@media only screen and (max-width: ${DESKTOP_M_BP}px) {
		padding: 40px 96px;
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 40px 48px;
		padding-top: 40px;
	}

	@media only screen and (max-width: 600px) {
		padding: 40px 24px;
	}
`;

export const MainSection = styled.div`
	position: relative;
	overflow: hidden;
	background-color: ${(p) => p.color};
	padding: 64px 72px;
	display: flex;
	flex-wrap: wrap;
	column-gap: 72px;
	row-gap: 64px;
	justify-content: space-around;
	align-items: center;
	border-radius: 15px;

	@media only screen and (max-width: ${DESKTOP_L_BP}px) {
		row-gap: 40px;
		column-gap: 48px;
	}

	@media only screen and (max-width: ${DESKTOP_M_BP}px) {
		justify-content: flex-start;
		padding: 64px 20%;
		row-gap: 64px;
		column-gap: 72px;
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 64px 72px;
	}

	@media only screen and (max-width: 600px) {
		padding: 40px 24px;
	}
`;

export const SubSection = styled.div`
	max-width: 480px;
	width: 100%;
	margin: 0;
	padding: 0;
	& > *:not(:last-child) {
		margin-bottom: 1.5rem;
	}

	@media only screen and (max-width: ${DESKTOP_M_BP}px) {
		max-width: 100%;
	}
	.cta-block-logos {
		max-height: 30px;
	}
`;

export const CareerQuizWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;

export const LinksWrapper = styled.div`
	display: flex;
	column-gap: 1.5rem;
	row-gap: 0.5rem;
	flex-wrap: wrap;
`;

export const InputWithButtonWrapper = styled.div`
	max-width: 480px;
	label {
		margin-bottom: 0.5rem;
		display: block;
		color: var(--primary-neutral-nightshade-600);
	}
	.required::after {
		content: "*";
		margin-left: 0.25rem;
		color: var(--secondary-red-300);
	}

	.dangerously-set-text {
		margin-top: 1rem;

		& > *:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
	.dangerously-set-text * {
		font-size: inherit;
		font-family: inherit;
		line-height: inherit;
		letter-spacing: inherit;
		paragraph-spacing: inherit;
		strong {
			font-weight: var(--fw-haffer-xh-2);
		}
		a {
			text-decoration: none;
			color: var(--secondary-purple-300);
		}
		a:hover {
			text-decoration: underline;
		}
	}
	.waitlist-success-msg-wrapper {
		display: flex;
		align-items: center;
		.icon-waitlist {
			margin: 0 8px;
		}
	}
`;

export const Flow = styled.div`
	& > *:not(:last-child) {
		margin-bottom: ${(props) => props.gap || "1rem"};
	}
`;

export const PhoneEmailContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	column-gap: 1rem;
	row-gap: 0.5rem;
	align-items: stretch;
`;
